import React from 'react';
import './App.css';
// import Navbar from './Components/Navbar';
import Homepage from './Components/Homepage';
import Services from './Components/Services';
import Privacypolicy from "./Components/Privacypolicy"; 
import Termsofservice from "./Components/Termsofservice"; 
import Unsubscribe from "./Components/Unsubscribe";
import Industries from './Components/Industries';
import ContactForm from './Components/Form';
import Button from '@mui/material/Button';
import { BrowserRouter, Routes, Route } from "react-router-dom";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/services/" element={<Services />}></Route>

        <Route path="/privacypolicy/" element={<Privacypolicy />}></Route>
        <Route path="/termsofservice/" element={<Termsofservice />}></Route>
        <Route path="/unsubscribe/" element={<Unsubscribe />}></Route>

        <Route path="/industries/" element={<Industries />}></Route>
        <Route path="/ContactForm/" element={<ContactForm />}></Route>
        <Route path="/ContactForm/:search" element={<ContactForm />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
//comment


